import { LoginSocial } from './LoginSocial';
import {
    ButtonsDividerStyled,
    ButtonsStyled,
    ClubHeadingStyled,
    LoginLostPassLinkStyled,
} from 'components/Blocks/Popup/Login/Login.style';
import Button from 'components/Forms/Button/Button';
import Form from 'components/Forms/Form/Form';
import FormLine from 'components/Forms/Lib/FormLine/FormLine';
import FormLineError from 'components/Forms/Lib/FormLineError/FormLineError';
import TextInput from 'components/Forms/TextInput/TextInput';
import { useLoginForm, useLoginFormMeta } from 'components/Pages/Login/formMeta';
import { useAuth } from 'hooks/auth/useAuth';
import { useHandleFormErrors } from 'hooks/forms/UseHandleFormErrors';
import { useHandleFormSuccessfulSubmit } from 'hooks/forms/UseHandleFormSuccessfulSubmit';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCallback } from 'react';
import { Controller, FormProvider, SubmitHandler } from 'react-hook-form';
import { usePersistStore } from 'store/usePersistStore';
import { LoginFormProps } from 'types/login';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

export const LoginForm: FC<LoginFormProps> = ({
    shouldDeleteOldCustomerCart,
    shouldOverwriteCustomerUserCart,
    onSuccessSubmit,
    defaultEmail,
}) => {
    const t = useTypedTranslationFunction();
    const { url } = useDomainConfig();
    const [resetPasswordUrl] = getInternationalizedStaticUrls(['/reset-password'], url);
    const { cartUuid } = usePersistStore((s) => s);
    const [[loginResult, login]] = useAuth();
    const [formProviderMethods, defaultValues] = useLoginForm(defaultEmail);
    const {
        fields: { email: emailField, password: passwordField },
        formName,
    } = useLoginFormMeta(formProviderMethods);

    useHandleFormSuccessfulSubmit(loginResult, formProviderMethods, defaultValues, undefined, {
        blur: true,
    });
    useHandleFormErrors(loginResult.error, formProviderMethods, 'login popup');

    const onLoginHandler = useCallback<SubmitHandler<{ email: string; password: string }>>(
        async (data, event) => {
            event?.preventDefault();

            await login(
                {
                    email: data.email,
                    password: data.password,
                    previousCartUuid: cartUuid,
                    deleteOldCustomerCart: shouldDeleteOldCustomerCart ?? null,
                },
                undefined,
                onSuccessSubmit,
            );
        },
        [cartUuid, login, onSuccessSubmit, shouldDeleteOldCustomerCart],
    );

    return (
        <>
            <ClubHeadingStyled>{t('Log in')}</ClubHeadingStyled>
            <FormProvider {...formProviderMethods}>
                <Form name="login" onSubmit={formProviderMethods.handleSubmit(onLoginHandler)}>
                    <Controller
                        name={emailField.name}
                        render={({ fieldState: { isTouched, invalid, error }, field }) => (
                            <FormLine bottomGap>
                                <TextInput
                                    id={`${formName}-${emailField.name}`}
                                    name={emailField.name}
                                    label={t('Email')}
                                    required
                                    type="text"
                                    isTouched={isTouched}
                                    hasError={invalid}
                                    fieldRef={field}
                                />
                                <FormLineError
                                    textInputSize="small"
                                    error={error}
                                    inputType="text-input"
                                    data-testid={`${formName}-${emailField.name}-error`}
                                />
                            </FormLine>
                        )}
                    />
                    <Controller
                        name={passwordField.name}
                        render={({ fieldState: { isTouched, invalid, error }, field }) => (
                            <FormLine>
                                <TextInput
                                    id={`${formName}-${passwordField.name}`}
                                    name={passwordField.name}
                                    label={passwordField.label}
                                    required
                                    type="password"
                                    isTouched={isTouched}
                                    hasError={invalid}
                                    fieldRef={field}
                                />
                                <FormLineError
                                    textInputSize="small"
                                    error={error}
                                    inputType="text-input-password"
                                    data-testid={`${formName}-${passwordField.name}-error`}
                                />
                            </FormLine>
                        )}
                    />
                    <ButtonsStyled>
                        <Button type="submit" variant="primary" data-testid="blocks-popup-login-submit">
                            {t('Log-in')}
                        </Button>
                        <ButtonsDividerStyled />
                        <LoginLostPassLinkStyled href={resetPasswordUrl} type="static">
                            {t('Lost your password?')}
                        </LoginLostPassLinkStyled>
                    </ButtonsStyled>
                    <LoginSocial
                        cartUuid={cartUuid}
                        shouldOverwriteCustomerUserCart={shouldOverwriteCustomerUserCart}
                    />
                </Form>
            </FormProvider>
        </>
    );
};
