import { GetServerSidePropsContext, NextPageContext } from 'next';
import { parseCookies } from 'nookies';
import { SocialLoginDataType } from 'types/cookie';
import { UserConsentFormType } from 'types/form';

export const getUserConsentCookie = (ctx?: GetServerSidePropsContext | NextPageContext): UserConsentFormType | null => {
    const userConsentCookieString: string | undefined = parseCookies(ctx).userConsent;

    return userConsentCookieString ? JSON.parse(userConsentCookieString) : null;
};

export const getSocialLoginDataCookie = (
    ctx?: GetServerSidePropsContext | NextPageContext,
): SocialLoginDataType | null => {
    const registrationDataCookie: string | null = parseCookies(ctx).registrationDataCookie;

    return registrationDataCookie ? JSON.parse(registrationDataCookie) : null;
};
