import { BreadcrumbsSkeleton } from 'components/Layout/Breadcrumbs/BreadcrumbsSkeleton';
import Webline from 'components/Layout/Webline/Webline';
import Skeleton from 'react-loading-skeleton';

export const RegistrationPageSkeleton: FC = () => {
    return (
        <Webline>
            <BreadcrumbsSkeleton count={3} />
            <div className="m-auto mb-12 max-w-[720px]">
                <Skeleton className="h-10 w-full vl:w-4/5" />
                <Skeleton className="mb-6 h-[400px] w-full" />
            </div>
        </Webline>
    );
};
