import { showSuccessMessage } from 'components/Helpers/Toasts';
import {
    LoginApi,
    LoginVariablesApi,
    LogoutApi,
    LogoutVariablesApi,
    useLoginApi,
    useLogoutApi,
} from 'graphql/generated';
import { canUseDom } from 'helpers/canUseDom';
import { removeSocialLoginDataCookie } from 'helpers/cookies/removeSocialLoginData';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useRouter } from 'next/router';
import { usePersistStore } from 'store/usePersistStore';
import { UseMutationState } from 'urql';
import { removeTokensFromCookies, setTokensToCookie } from 'utils/Auth/TokensFromCookies';

export const useAuth = (): [
    [UseMutationState<LoginApi, LoginVariablesApi>, typeof loginHandler],
    [UseMutationState<LogoutApi, LogoutVariablesApi>, typeof logoutHandler],
] => {
    const [loginUseMutationResponse, login] = useLoginApi();
    const [logoutUseMutationResponse, logout] = useLogoutApi();
    const t = useTypedTranslationFunction();
    const router = useRouter();
    const { updateCartUuidState, updateUserState } = usePersistStore((s) => s);

    const loginHandler = async (
        variables: LoginVariablesApi,
        rewriteUrl?: string | boolean,
        onSuccess?: () => void,
    ) => {
        const loginResult = await login(variables);

        if (loginResult.error !== undefined) {
            return;
        }

        const accessToken = loginResult.data?.Login.tokens.accessToken;
        const refreshToken = loginResult.data?.Login.tokens.refreshToken;

        const uuid = loginResult.data?.Login.uuid;
        const email = loginResult.data?.Login.email;

        if (accessToken !== undefined && refreshToken !== undefined) {
            if (uuid !== undefined) {
                updateUserState({ userUuid: uuid });
            }
            if (email !== undefined) {
                updateUserState({ userEmail: email });
            }

            updateCartUuidState(null);
            setTokensToCookie(accessToken, refreshToken);
            removeSocialLoginDataCookie();

            if (loginResult.data?.Login.showCartMergeInfo === true) {
                updateUserState({ loginLoading: 'loading-with-cart-modifications' });
            } else {
                updateUserState({ loginLoading: 'loading' });
            }

            if (onSuccess) {
                onSuccess();
            }

            if (canUseDom() && typeof rewriteUrl !== 'boolean') {
                window.location.href = rewriteUrl ?? router.asPath;
            }
        }
    };

    const logoutHandler = async () => {
        const logoutResult = await logout({});

        if (logoutResult.data?.Logout === true) {
            updateUserState({ userUuid: null });
            updateUserState({ userEmail: null });
            removeTokensFromCookies();
            showSuccessMessage(t('Successfully logged out'));

            if (canUseDom()) {
                router.reload();
            }
        }
    };

    return [
        [loginUseMutationResponse, loginHandler],
        [logoutUseMutationResponse, logoutHandler],
    ];
};
