import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { ButtonDefaultPropType } from 'components/Forms/Button/Button';
import { AnchorHTMLAttributes, DetailedHTMLProps, ReactElement } from 'react';
import { FriendlyPagesTypesKeys } from 'types/friendlyUrl';
import { UrlObject } from 'url';
import twMerge from 'utils/twMerge';

type LinkProps = Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'href' | 'type'> &
    ButtonDefaultPropType & {
        linkType?: 'external';
        isButton?: boolean;
        href: string | UrlObject;
        children?: ReactElement | string;
        type?: FriendlyPagesTypesKeys | 'static' | 'static-presumed-category' | 'registration';
    };

const Link: FC<LinkProps> = ({
    children,
    href,
    isButton,
    linkType,
    size = 'default',
    styleType,
    variant = 'default',
    className,
    type = 'static',
    ...restProps
}) => {
    const testIdentifier =
        'basic-link' + (linkType !== undefined ? '-' + linkType : '') + (isButton === true ? '-button' : '');

    if (linkType === 'external') {
        if (isButton === true) {
            return (
                <a
                    className={twMerge(
                        size === 'default' &&
                            'min-h-[50px] py-[13px] px-9 text-h6 lg:text-h5 [&>svg:first-child]:mr-3 [&>svg:last-child:not(:first-child)]:ml-3',
                        size === 'bigger' &&
                            'min-h-[50px] py-[13px] px-9 text-h6 [&>svg:first-child]:mr-1 [&>svg:last-child:not(:first-child)]:ml-1',
                        size === 'small' &&
                            'min-h-[40px] py-[9px] px-8 text-body [&>svg:first-child]:mr-1 [&>svg:last-child:not(:first-child)]:ml-1',
                        size === 'smallNarrow' &&
                            'min-h-[40px] py-[9px] px-3 text-body [&>svg:first-child]:mr-3 [&>svg:last-child:not(:first-child)]:ml-3',
                        size === 'extraSmall' &&
                            'min-h-[40px] py-[9px] px-3 text-small [&>svg:first-child]:mr-1 [&>svg:last-child:not(:first-child)]:ml-1',
                        variant === 'default' &&
                            'bg-primary text-white hover:bg-[#3b3b3b] hover:text-white [&>svg]:hover:text-white',
                        variant === 'primary' &&
                            'bg-green text-white hover:bg-[#004d3a] hover:text-white [&>svg]:hover:text-white',
                        variant === 'secondary' &&
                            'bg-white text-primary shadow-grayShadow hover:bg-white hover:text-primary hover:shadow-none disabled:bg-[#e5e5e5] hover:[&>svg]:text-primary disabled:[&>svg]:text-[#888888]',
                        variant === 'link' &&
                            'bg-transparent text-primary underline decoration-border decoration-1 underline-offset-2 hover:bg-transparent hover:text-primary hover:decoration-primary disabled:bg-transparent hover:[&>svg]:text-primary disabled:[&>svg]:text-grayDarker',
                        'inline-block cursor-pointer border-none text-center decoration-0 outline-0 transition-colors ease-defaultTransition hover:decoration-0 active:scale-95',
                        styleType === 'light' ? 'font-light' : 'font-medium',
                        className,
                    )}
                    data-testid={testIdentifier}
                    href={href as string}
                    {...restProps}
                >
                    {children}
                </a>
            );
        }

        return (
            <a
                className={twMerge('ui-link', className)}
                data-testid={testIdentifier}
                href={href as string}
                {...restProps}
            >
                {children}
            </a>
        );
    }

    if (isButton === true) {
        return (
            <ExtendedNextLink
                href={href}
                className={twMerge(
                    size === 'default' &&
                        'min-h-12 py-4 px-9 text-h6 lg:text-h5 [&>svg:first-child]:mr-3 [&>svg:last-child:not(:first-child)]:ml-3',
                    size === 'bigger' &&
                        'min-h-12 py-4 px-9 text-h6 [&>svg:first-child]:mr-1 [&>svg:last-child:not(:first-child)]:ml-1',
                    size === 'small' &&
                        'min-h-10 py-[9px] px-8 text-body [&>svg:first-child]:mr-1 [&>svg:last-child:not(:first-child)]:ml-1',
                    size === 'smallNarrow' &&
                        'min-h-10 py-[9px] px-3 text-body [&>svg:first-child]:mr-3 [&>svg:last-child:not(:first-child)]:ml-3',
                    size === 'extraSmall' &&
                        'min-h-10 py-[9px] px-3 text-small [&>svg:first-child]:mr-1 [&>svg:last-child:not(:first-child)]:ml-1',
                    variant === 'default' &&
                        'bg-primary text-white hover:bg-[#3b3b3b] hover:text-white [&>svg]:hover:text-white',
                    variant === 'primary' &&
                        'bg-green text-white hover:bg-[#004d3a] hover:text-white [&>svg]:hover:text-white',
                    variant === 'secondary' &&
                        'bg-white text-primary shadow-grayShadow hover:bg-white hover:text-primary hover:shadow-none disabled:bg-[#e5e5e5] hover:[&>svg]:text-primary disabled:[&>svg]:text-[#888888]',
                    variant === 'link' &&
                        'bg-transparent text-primary underline decoration-border decoration-1 underline-offset-2 hover:bg-transparent hover:text-primary hover:decoration-primary disabled:bg-transparent hover:[&>svg]:text-primary disabled:[&>svg]:text-grayDarker',
                    'inline-block cursor-pointer rounded-md border-none text-center leading-5 no-underline outline-0 transition-colors ease-defaultTransition hover:no-underline active:scale-95',
                    styleType === 'light' ? 'font-light' : 'font-medium',
                    className,
                )}
                data-testid={testIdentifier}
                {...restProps}
                type={type}
            >
                {children}
            </ExtendedNextLink>
        );
    }

    return (
        <ExtendedNextLink
            href={href}
            className={twMerge('ui-link', className)}
            data-testid={testIdentifier}
            {...restProps}
            type={type}
        >
            {children}
        </ExtendedNextLink>
    );
};

/* @component */
export default Link;
