import { MouseEventHandler, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { twJoin } from 'tailwind-merge';
import twMerge from 'utils/twMerge';

export type OverlayProps = {
    withWrapper?: boolean;
    className?: React.ComponentProps<'div'>['className'];
    isActive?: boolean;
    isHiddenOnDesktop?: boolean;
    onClick?: MouseEventHandler;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
};

const Overlay: FC<OverlayProps> = ({
    withWrapper = true,
    className = '',
    isActive = true,
    isHiddenOnDesktop = false,
    onClick,
    children,
    onMouseEnter,
    onMouseLeave,
}) => {
    const testIdentifier = 'basic-overlay';

    const innerOverlayTwClass = twJoin(
        'items-center bottom-0 flex inset-0 justify-center fixed bg-black/60 cursor-pointer opacity-0 pointer-events-none transition-opacity z-aboveMenu',
        isActive && 'opacity-100 pointer-events-auto',
        isHiddenOnDesktop && 'vl:hidden',
    );

    const InnerOverlay = useMemo(
        () => (
            <div
                className={twMerge(innerOverlayTwClass, className)}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {children}
            </div>
        ),
        [children, className, innerOverlayTwClass, onClick, onMouseLeave, onMouseEnter],
    );

    if (!withWrapper) {
        return InnerOverlay;
    }

    return (
        <div className="overlay-wrapper" data-testid={testIdentifier}>
            <CSSTransition in timeout={500} classNames="overlay" unmountOnExit>
                {InnerOverlay}
            </CSSTransition>
        </div>
    );
};

export default Overlay;
