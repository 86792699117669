import { UserConsentContainer } from 'components/Blocks/UserConsent/UserConsentContainer';
import { showInfoMessage, showSuccessMessage } from 'components/Helpers/Toasts';
import { Error500WithBoundary } from 'components/Pages/ErrorPage/500/Error500';
import { Error503 } from 'components/Pages/ErrorPage/503/Error503';
import { useSettings } from 'connectors/Settings/Settings';
import { SocialLoginDataContext } from 'context/SocialLoginDataProvider/SocialLoginDataProvider';
import { UserConsentContext } from 'context/UserConsentProvider/UserConsentProvider';
import { injectScripts } from 'helpers/administration/scripts';
import { ServerSidePropsType } from 'helpers/InitServerSideProps';
import { useReloadCart } from 'hooks/cart/useReloadCart';
import { useSetGeneralSlice } from 'hooks/setGeneralSlice';
import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { useRedirectLoader } from 'hooks/ui/useRedirectStatusProgress';
import { useSetDomainConfig } from 'hooks/useDomainConfig';
import { useAfterUserEntry } from 'hooks/user/useAfterUserEntry';
import { useSetUserSlice } from 'hooks/useSetUserSlice';
import { PersooWatchDog } from 'modules/persoo/PersooWatchDog';
import { usePersooAddToCart } from 'modules/persoo/usePersooAddToCart';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import { usePersistStore } from 'store/usePersistStore';

interface PageContentProviderProps {
    pageProps: ServerSidePropsType;
}

const AddToCartPopup = dynamic(
    () =>
        import('components/Blocks/Product/AddToCartPopup/AddToCartPopup').then((component) => component.AddToCartPopup),
    {
        ssr: false,
    },
);

const PageContentProvider: FC<PageContentProviderProps> = ({ children, pageProps }) => {
    const updateUserState = usePersistStore((s) => s.updateUserState);
    const addToCartPopupData = usePersistStore((s) => s.addToCartPopupData);
    const addToCartFetching = usePersistStore((s) => s.addToCartFetching);

    const { t } = useTranslation();
    const {
        domainConfig,
        defaultStoreUuid,
        cartUuid,
        lastVisitedProductsCatNumbers,
        userConsentCookie,
        socialLoginData,
        isMaintenance,
    } = pageProps;

    const settings = useSettings();
    useSetDomainConfig(domainConfig);
    useSetUserSlice({ defaultStoreUuid: defaultStoreUuid, cartUuid: cartUuid });
    useSetGeneralSlice({ lastVisitedProductsCatNumbers: lastVisitedProductsCatNumbers ?? undefined });

    useEffectOnce(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const scripts = settings?.scripts || [];
        injectScripts(scripts, 'all');
    });

    const { loginLoading } = usePersistStore((s) => s);

    usePersooAddToCart();
    useReloadCart();
    useRedirectLoader();
    useAfterUserEntry();

    useEffect(() => {
        if (loginLoading === 'not-loading') {
            return;
        }

        showSuccessMessage(t('Successfully logged in'));

        if (loginLoading === 'loading-with-cart-modifications') {
            showInfoMessage(t('Your cart has been modified. Please check the changes.'));
        }
        updateUserState({ loginLoading: 'not-loading' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginLoading]);

    return (
        <>
            <ToastContainer autoClose={6000} position="top-center" theme="colored" />
            <ErrorBoundary FallbackComponent={Error500WithBoundary}>
                <UserConsentContext.Provider value={userConsentCookie}>
                    <SocialLoginDataContext.Provider value={socialLoginData}>
                        <>
                            {userConsentCookie === null && <UserConsentContainer />}
                            {isMaintenance ? <Error503 /> : children}
                        </>
                    </SocialLoginDataContext.Provider>
                </UserConsentContext.Provider>
                <PersooWatchDog />
                {addToCartPopupData && (
                    <AddToCartPopup
                        gtmListName={addToCartPopupData.gtmListName}
                        listIndex={addToCartPopupData.listIndex}
                        onCloseCallback={() => updateUserState({ addToCartPopupData: null })}
                        product={addToCartPopupData.product}
                        fetching={addToCartFetching}
                    />
                )}
            </ErrorBoundary>
            <div id="portal" className="absolute top-0 left-0 h-px w-px" />
        </>
    );
};

export default PageContentProvider;
