import { useCurrentCustomerData } from 'connectors/customer/CurrentCustomer';
import { useEffect } from 'react';
import { usePersistStore } from 'store/usePersistStore';
import { onGtmUserEntryEventHandler } from 'utils/Gtm/EventHandlers';

export const useAfterUserEntry = () => {
    const { userEntry, updateUserEntryState } = usePersistStore((store) => store);
    const currentCustomerData = useCurrentCustomerData();
    useEffect(() => {
        if (userEntry && currentCustomerData) {
            onGtmUserEntryEventHandler(userEntry === 'login' ? 'ec.login' : 'ec.registration', currentCustomerData);
            updateUserEntryState(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEntry, currentCustomerData]);
};
