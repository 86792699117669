import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { useSettings } from 'connectors/Settings/Settings';
import { LoginTypeEnumApi } from 'graphql/generated';
import { isDomainLocale } from 'helpers/isDomainLocale';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { FacebookIcon, GoogleIcon, SeznamIcon } from 'public/svg/IconsSvg';
import twMerge from 'utils/twMerge';

type LoginProps = {
    cartUuid: string | null;
    shouldOverwriteCustomerUserCart?: boolean;
    itemsInLine?: boolean;
};

const TEST_IDENTIFIER = 'pages-login-social';

export const LoginSocial: FC<LoginProps> = ({ cartUuid, shouldOverwriteCustomerUserCart, itemsInLine }) => {
    const t = useTypedTranslationFunction();
    const settings = useSettings();
    const { defaultLocale } = useDomainConfig();
    const isCzechDomain = isDomainLocale(defaultLocale, 'cs');

    if (settings?.socialNetworkLoginConfig.length === 0) {
        return null;
    }

    const getSocialNetworkLoginUrl = (
        socialNetwork: 'facebook' | 'google' | 'seznam',
        cartUuid: string | null,
        shouldOverwriteCustomerUserCart: boolean | undefined,
    ) => {
        let url = `/social-network/login/${socialNetwork}`;
        if (cartUuid) {
            url += `?cartUuid=${cartUuid}&shouldOverwriteCustomerUserCart=${
                shouldOverwriteCustomerUserCart ? 'true' : 'false'
            }`;
        }

        return url;
    };

    return (
        <div
            className={twMerge(
                'mt-7 flex w-full flex-col justify-center gap-4 border-t border-gray pt-4',
                itemsInLine && 'flex-col lg:flex-row',
            )}
            data-testid={TEST_IDENTIFIER}
        >
            {settings?.socialNetworkLoginConfig.includes(LoginTypeEnumApi.GoogleApi) && (
                <SocialNetworkLoginLink
                    href={getSocialNetworkLoginUrl(
                        LoginTypeEnumApi.GoogleApi,
                        cartUuid,
                        shouldOverwriteCustomerUserCart,
                    )}
                    socialNetwork={LoginTypeEnumApi.GoogleApi}
                >
                    {t('Login via Google')}
                </SocialNetworkLoginLink>
            )}

            {settings?.socialNetworkLoginConfig.includes(LoginTypeEnumApi.FacebookApi) && (
                <SocialNetworkLoginLink
                    href={getSocialNetworkLoginUrl(
                        LoginTypeEnumApi.FacebookApi,
                        cartUuid,
                        shouldOverwriteCustomerUserCart,
                    )}
                    socialNetwork={LoginTypeEnumApi.FacebookApi}
                >
                    {t('Login via Facebook')}
                </SocialNetworkLoginLink>
            )}

            {isCzechDomain && settings?.socialNetworkLoginConfig.includes(LoginTypeEnumApi.SeznamApi) && (
                <SocialNetworkLoginLink
                    href={getSocialNetworkLoginUrl(
                        LoginTypeEnumApi.SeznamApi,
                        cartUuid,
                        shouldOverwriteCustomerUserCart,
                    )}
                    socialNetwork={LoginTypeEnumApi.SeznamApi}
                >
                    {t('Login via Seznam')}
                </SocialNetworkLoginLink>
            )}
        </div>
    );
};

const SocialNetworkLoginLink: FC<{ href: string; socialNetwork: LoginTypeEnumApi }> = ({
    href,
    socialNetwork,
    children,
}) => {
    return (
        <ExtendedNextLink href={href} type="static" className="flex items-center">
            <>
                <div className="flex h-8 w-10 items-center justify-center rounded-md bg-gray">
                    <SocialNetworkIcon socialNetwork={socialNetwork} />
                </div>
                <div className="ml-3 cursor-pointer text-body font-bold text-black underline">{children}</div>
            </>
        </ExtendedNextLink>
    );
};

const SocialNetworkIcon: FC<{ socialNetwork: LoginTypeEnumApi }> = ({ socialNetwork }) => {
    switch (socialNetwork) {
        case LoginTypeEnumApi.FacebookApi:
            return <FacebookIcon className="h-[22px] w-auto text-[#1877f2]" />;
        case LoginTypeEnumApi.GoogleApi:
            return <GoogleIcon className="h-[22px] w-auto" />;
        case LoginTypeEnumApi.SeznamApi:
            return <SeznamIcon className="h-[22px] w-auto" />;
        default:
            return null;
    }
};
