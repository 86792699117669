import { LastOrderFragmentApi, ListedStoreFragmentApi } from 'graphql/generated';
import { AvailabilityStatusEnum } from 'types/availability';
import { CartItemType } from 'types/cart';
import { CurrentCustomerType } from 'types/customer';
import {
    GtmAvailabilityType,
    GtmCartItemType,
    GtmListedProductType,
    GtmOrderItemType,
    GtmProductInterface,
    GtmShippingInfoType,
    GtmUserEntryInfoType,
    GtmUserInfoType,
} from 'types/gtm';
import { OrderDetailItemType } from 'types/orders';
import { PickupPointType } from 'types/pickupPoint';
import {
    AutocompleteProductType,
    ListedProductType,
    ProductDetailType,
    ProductInterfaceType,
    SimpleProductType,
    VariantDetailType,
} from 'types/product';

const mapGtmAvailability = (product: ProductInterfaceType): GtmAvailabilityType => {
    if (product.availability.status === AvailabilityStatusEnum.inStock) {
        return 'InStock';
    }

    return product.availableStoresCount > 0 ? 'Store' : 'OutOfStock';
};

export const mapGtmCartItemType = (
    cartItem: CartItemType,
    listIndex: number | null,
    domainUrl: string,
    quantity?: number,
): GtmCartItemType => ({
    ...mapGtmProductInterface(cartItem.product, listIndex, domainUrl),
    availability: mapGtmAvailability(cartItem.product),
    quantity: quantity ?? cartItem.quantity,
});

export const mapGtmOrderItemType = (orderItem: OrderDetailItemType): GtmOrderItemType => {
    const result: GtmOrderItemType = {
        id: orderItem.productId,
        name: orderItem.name,
        uuid: orderItem.productUuid,
        price: orderItem.unitPrice.priceWithoutVat,
        priceWithTax: orderItem.unitPrice.priceWithVat,
        tax: orderItem.unitPrice.vatAmount,
        sku: orderItem.productCatnum,
        variant: 'colorVariant' in orderItem ? orderItem.colorVariant?.name ?? '' : '',
        quantity: orderItem.quantity,
    };

    return result;
};

export const mapGtmListedProductType = (
    product: ListedProductType | SimpleProductType | AutocompleteProductType,
    listIndex: number,
    domainUrl: string,
): GtmListedProductType => ({
    ...mapGtmProductInterface(product, listIndex, domainUrl),
});

export const mapGtmProductDetailType = (
    product: ProductDetailType | VariantDetailType,
    listIndex: number,
    domainUrl: string,
): GtmProductInterface => mapGtmProductInterface(product, listIndex, domainUrl);

const mapGtmProductInterface = (
    productInterface: ProductInterfaceType,
    listIndex: number | null,
    domainUrl: string,
): GtmProductInterface => {
    let productUrl;

    if (domainUrl.endsWith('/')) {
        const domainUrlWithoutTrailingSlash = domainUrl.slice(0, domainUrl.length - 1);
        productUrl = domainUrlWithoutTrailingSlash + productInterface.slug;
    } else {
        productUrl = domainUrl + productInterface.slug;
    }

    const result: GtmProductInterface = {
        id: productInterface.id,
        name: productInterface.fullName,
        availability: mapGtmAvailability(productInterface),
        imageUrl: mapGtmProductInterfaceImageUrl(productInterface),
        labels: productInterface.flags.map((simpleFlagType) => simpleFlagType.name),
        uuid: productInterface.uuid,
        price: productInterface.price.priceWithoutVat,
        priceWithTax: productInterface.price.priceWithVat,
        tax: productInterface.price.vatAmount,
        url: productUrl,
        sku: productInterface.catalogNumber,
        brand: productInterface.brand?.name ?? '',
        categories: productInterface.categoryNames,
        variant: 'currentColorVariant' in productInterface ? productInterface.currentColorVariant?.name ?? '' : '',
    };

    if (listIndex !== null) {
        result.listIndex = listIndex + 1;
    }

    return result;
};

const mapGtmProductInterfaceImageUrl = (productInterface: ProductInterfaceType): string | undefined => {
    if ('image' in productInterface) {
        return productInterface.image ?? undefined;
    }

    return productInterface.images.length > 0 ? productInterface.images[0] : undefined;
};

export const mapGtmShippingInfo = (pickupPoint: PickupPointType | null): GtmShippingInfoType => {
    let shippingDetail = '';

    if (pickupPoint !== null) {
        shippingDetail = `${pickupPoint.name}, ${pickupPoint.street}, ${pickupPoint.city}, ${
            pickupPoint.country?.name ?? ''
        }, ${pickupPoint.postcode}`;
    }

    return {
        shippingDetail,
    };
};

export const getGtmPickupPointFromStore = (
    pickupPointIdentifier: string,
    store: ListedStoreFragmentApi,
): PickupPointType => ({
    city: store.city ?? '',
    country: store.country,
    description: store.description ?? '',
    disabled: store.disabled,
    email: store.email,
    identifier: pickupPointIdentifier,
    locationLatitude: store.locationLatitude,
    locationLongitude: store.locationLongitude,
    name: store.name,
    openingHours: store.openingHours,
    postcode: store.postcode ?? '',
    reservationDisabled: store.reservationDisabled,
    street: store.street ?? '',
    telephone: store.telephone,
});

export const getGtmPickupPointFromLastOrder = (
    pickupPointIdentifier: string,
    lastOrder: LastOrderFragmentApi,
): PickupPointType => ({
    city: lastOrder.deliveryCity ?? '',
    country: {
        name: lastOrder.deliveryCountry?.name ?? '',
        code: lastOrder.deliveryCountry?.code ?? '',
    },
    description: '',
    disabled: false,
    email: null,
    identifier: pickupPointIdentifier,
    locationLatitude: null,
    locationLongitude: null,
    name: '',
    openingHours: null,
    postcode: lastOrder.deliveryPostcode ?? '',
    reservationDisabled: false,
    street: lastOrder.deliveryStreet ?? '',
    telephone: null,
});

export const mapGtmUserEntryInfoFromCurrentCustomer = ({
    uuid,
    email,
    firstName,
    lastName,
    loginInfo: { loginType, externalId },
}: CurrentCustomerType): GtmUserEntryInfoType => ({
    id: uuid,
    email,
    name: firstName,
    surname: lastName,
    loginType,
    externalId,
});

export const mapGtmUserEntryInfoFromGtmUserInfo = ({
    id,
    email,
    name,
    surname,
    loginType,
    externalId,
}: GtmUserInfoType): GtmUserEntryInfoType => ({
    id,
    email,
    name,
    surname,
    loginType,
    externalId,
});
