import {
    CurrentCustomerUserQueryApi,
    DeliveryAddressFragmentApi,
    PillCountsApi,
    useCurrentCustomerUserQueryApi,
} from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useMemo } from 'react';
import { CurrentCustomerType, DeliveryAddressType, PillCountsType } from 'types/customer';

export function useCurrentCustomerData(): CurrentCustomerType | null | undefined {
    const [{ data, error }] = useCurrentCustomerUserQueryApi();
    const { useTaxId } = useDomainConfig();
    useQueryError(error);

    const currentCustomerUser = data?.currentCustomerUser;

    return useMemo(() => {
        if (currentCustomerUser === undefined) {
            return undefined;
        }

        return mapCurrentCustomerApiData(currentCustomerUser, useTaxId);
    }, [currentCustomerUser, useTaxId]);
}

const mapCurrentCustomerApiData = (
    apiCurrentCustomerData: CurrentCustomerUserQueryApi['currentCustomerUser'],
    useTaxId: boolean,
): CurrentCustomerType | null => {
    if (apiCurrentCustomerData === null) {
        return null;
    }

    return {
        ...apiCurrentCustomerData,
        companyCustomer: apiCurrentCustomerData.__typename === 'CompanyCustomerUser',
        telephone: apiCurrentCustomerData.telephone === null ? '' : apiCurrentCustomerData.telephone,
        birthDay: apiCurrentCustomerData.birthDay === null ? null : new Date(apiCurrentCustomerData.birthDay),
        companyName:
            apiCurrentCustomerData.__typename === 'CompanyCustomerUser' && apiCurrentCustomerData.companyName !== null
                ? apiCurrentCustomerData.companyName
                : '',
        companyNumber:
            apiCurrentCustomerData.__typename === 'CompanyCustomerUser' && apiCurrentCustomerData.companyNumber !== null
                ? apiCurrentCustomerData.companyNumber
                : '',
        companyTaxNumber:
            apiCurrentCustomerData.__typename === 'CompanyCustomerUser' &&
            apiCurrentCustomerData.companyTaxNumber !== null
                ? apiCurrentCustomerData.companyTaxNumber
                : '',
        companyTaxIdNumber:
            useTaxId &&
            apiCurrentCustomerData.__typename === 'CompanyCustomerUser' &&
            apiCurrentCustomerData.companyTaxIdNumber !== null
                ? apiCurrentCustomerData.companyTaxIdNumber
                : '',
        deliveryAddresses: mapDeliveryAddresses(apiCurrentCustomerData.deliveryAddresses),
        passwordOld: '',
        passwordFirst: '',
        passwordSecond: '',
        pillCounts: mapPillCounts(apiCurrentCustomerData.pillCounts),
        customerCards: apiCurrentCustomerData.customerCards,
        codDisabled: apiCurrentCustomerData.codDisabled,
        loginInfo: apiCurrentCustomerData.loginInfo,
    };
};

export const mapDeliveryAddress = (apiDeliveryAddressData: DeliveryAddressFragmentApi): DeliveryAddressType => {
    return {
        ...apiDeliveryAddressData,
        companyName: apiDeliveryAddressData.companyName ?? '',
        street: apiDeliveryAddressData.street ?? '',
        city: apiDeliveryAddressData.city ?? '',
        postcode: apiDeliveryAddressData.postcode ?? '',
        telephone: apiDeliveryAddressData.telephone ?? '',
        firstName: apiDeliveryAddressData.firstName ?? '',
        lastName: apiDeliveryAddressData.lastName ?? '',
        country: apiDeliveryAddressData.country?.name ?? '',
    };
};

export const mapDeliveryAddresses = (apiDeliveryAddressesData: DeliveryAddressFragmentApi[]): DeliveryAddressType[] => {
    return apiDeliveryAddressesData.map((address) => mapDeliveryAddress(address));
};

const mapPillCounts = (pillCountsData: PillCountsApi): PillCountsType => {
    return {
        watchDogItems: pillCountsData.watchDogItems,
    };
};
